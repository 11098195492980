<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('/media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img alt="Logo" src="/media/logos/logo-algoma-square-256.png" class="h-80px" />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100"
          id="kt_login_signin_form"
          @submit="onSubmitLogin"
          :validation-schema="resetPassword"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">Reset password</h1>
            <!--end::Title-->

            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4">Please set a new password here</div>
            <!--end::Link-->
          </div>
          <!--begin::Heading-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Label-->
            <label class="form-label fs-6 fw-bolder text-dark">Email</label>
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              autocomplete="off"
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
              <!--end::Label-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Input-->
            <Field
              class="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autocomplete="off"
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="fv-row mb-5">
            <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              type="password"
              placeholder=""
              name="password_confirmation"
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password_confirmation" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-lg btn-primary fw-bolder me-4"
            >
              <span class="indicator-label">Submit</span>

              <span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <!--end::Submit button-->

            <router-link
              :to="{name: 'sign-in'}"
              class="btn btn-lg btn-light-primary fw-bolder"
            >Cancel</router-link>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "reset-password",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLElement | null>(null);

    // Create form validation object
    const resetPassword = Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .min(6)
        .required()
        .label("Password"),
      password_confirmation: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    // Form submit function
    const onSubmitLogin = values => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values.token = route.params.token;

      // Send resetPassword request
      store
        .dispatch(Actions.RESET_PASSWORD, values)
        .then(response => {
          let text = "Whoops, something went wrong";
          let icon = "error";
          let action = () => {};

          if (response.data.status === 'passwords.user') {
            text = "Invalid account email was entered";
            icon = "warning";
          } else if (response.data.status === 'passwords.token') {
            text = "Your reset password request has expired, please request a new one";
            icon = "warning";
            action = () => router.push({ name: "forgot-password" });
          } else if (response.data.status === 'passwords.reset') {
            text = "New password was saved, please login";
            icon = "success";
            action = () => router.push({ name: "sign-in" });
          }

          Swal.fire({
            text,
            icon,
            buttonsStyling: false,
            confirmButtonText: "Ok, got it",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(action);
        })
        .catch(() => {
          Swal.fire({
            text: "Whoops, something went wrong",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        })
        .finally(() => {
          // Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    onMounted(() => {
      if (!route.params.token) {
        Swal.fire({
          text: 'Incorrect reset password url',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        }).then(() => {
          router.push({name: "sign-in"});
        });
      }
    });

    return {
      onSubmitLogin,
      resetPassword,
      submitButton,
    };
  }
});
</script>
